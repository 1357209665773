import React from "react";
import 'core-js/es';
import ReactDOM from "react-dom";
import {createRoot} from 'react-dom/client'
import "./index.less";
import App from "./App";
import { RecoilRoot } from "recoil";
import echarts from "echarts/lib/echarts";
import 'polyfill-object.fromentries';

import "@/assets/icons";
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()

// ReactDOM.render(
//   <RecoilRoot>
//     <App />
//   </RecoilRoot>,
//   document.getElementById("root")
// );


createRoot(document.getElementById('root')).render(<RecoilRoot>
 <App />
   </RecoilRoot>)